

// import React, { useState } from 'react';
// import JSZip from 'jszip';
// import { saveAs } from 'file-saver';

// function FileProcessor() {
//   const [files, setFiles] = useState([]);
//   const [deviceId, setDeviceId] = useState('');
//   const [headerTimeDelta, setHeaderTimeDelta] = useState(0);
//   const [timeString, setTimeString] = useState('');
//   const [selectedChoice, setSelectedChoice] = useState('');
//   const [prefix, setPrefix] = useState('');
//   const [number, setNumber] = useState('');
//   const [modifiedFiles, setModifiedFiles] = useState([]);
//   const [failedFiles, setFailedFiles] = useState([]);
//   const [isUploading, setIsUploading] = useState(false); // State to track if uploading
//   const [uploadSuccess, setUploadSuccess] = useState(false); // State to track success message

//   const handleFileChange = (event) => {
//     setFiles(Array.from(event.target.files));
//   };

//   const handleTimeStringChange = (event) => {
//     setTimeString(event.target.value);
//   };

//   const handleChoiceChange = (event) => {
//     setSelectedChoice(event.target.value);
//     setPrefix('');
//     setNumber('');
//   };

//   const handlePrefixChange = (event) => {
//     setPrefix(event.target.value);
//     setNumber('');
//   };

//   const handleNumberChange = (event) => {
//     setNumber(event.target.value);
//   };

//   const readData = (file) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const data = new Uint8Array(e.target.result);
//         const headerPosition = [];
//         for (let i = 0; i < data.length; i++) {
//           if (data[i] === 252 || data[i] === 253) {
//             headerPosition.push(i);
//           }
//         }

//         const header = data.slice(headerPosition[0] + 1, headerPosition[1]);
//         const headerStr = String.fromCharCode(...header);
//         const headerTstamp = parseInt(headerStr.substring(0, 10), 10);
//         resolve({ rawData: data, headerPosition, headerTstamp });
//       };
//       reader.onerror = (error) => reject(error);
//       reader.readAsArrayBuffer(file);
//     });
//   };

//   const updateDeviceIdHtime = (rawData, headerPosition, deviceId, headerTstamp) => {
//     const newDevice = Array.from(deviceId.toString()).map((char) => char.charCodeAt(0));
//     const newHeader = Array.from(headerTstamp.toString()).map((char) => char.charCodeAt(0));

//     for (let i = 0; i < newDevice.length; i++) {
//       rawData[headerPosition[0] + 11 + i] = newDevice[i];
//     }

//     for (let i = 0; i < newHeader.length; i++) {
//       rawData[headerPosition[0] + 1 + i] = newHeader[i];
//     }

//     return rawData;
//   };

//   const sendToBackend = async (zipBlob, isRetry = false) => {
//     setIsUploading(true); // Set uploading state to true
//     setUploadSuccess(false); // Reset success state

//     const formData = new FormData();
//     formData.append('file', zipBlob, 'updatedfiles.zip');
//     formData.append('prefix', prefix);
//     formData.append('number', number);
//     console.log(`Size of zipBlob: ${(zipBlob.size / (1024 * 1024)).toFixed(2)} MB`);

//     try {
//       const response = await fetch('https://analytics.dozeeapp.com/file', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.status === 200) {
//         console.log('Files successfully sent to backend!');
//         setUploadSuccess(true); // Set success state to true
//         if (isRetry) {
//           setFailedFiles([]);
//         }
//       } else {
//         console.error('Error sending files to backend:', response.statusText);
//         const failedResponse = await response.json();
//         setFailedFiles(failedResponse.failed_files || []);
//         handleRetryPrompt();
//       }
//     } catch (error) {
//       console.error('Exception while sending files to backend:', error);
//       handleRetryPrompt();
//     } finally {
//       setIsUploading(false); // Set uploading state to false after the process
//     }
//   };

//   const handleRetryPrompt = () => {
//     if (window.confirm('Some files failed to upload. Do you want to try again?')) {
//       retryFailedUploads();
//     }
//   };

//   const retryFailedUploads = () => {
//     if (failedFiles.length > 0) {
//       const zip = new JSZip();
//       for (const file of modifiedFiles) {
//         zip.file(file.name, file.rawData);
//       }

//       zip.generateAsync({ type: 'blob' }).then((content) => {
//         sendToBackend(content, true);
//       });
//     }
//   };

//   const startReading = async () => {
//     console.log("Total files =", files.length);
//     console.log(`Changing header time by ${headerTimeDelta / 60} mins Or ${headerTimeDelta / 3600} hrs`);

//     const epochTime = Math.floor(new Date(timeString).getTime() / 1000);
//     console.log("Epoch Time:", epochTime);

//     const deviceID = "modified";
//     if (!deviceID) {
//       console.error("Device ID could not be retrieved.");
//       return;
//     }
//     setDeviceId(deviceID);
//     console.log(deviceID);
//     let fileNumber = 0;
//     const zip = new JSZip();
//     const modifiedFilesBuffer = [];

//     for (const file of files) {
//       try {
//         const { rawData, headerPosition, headerTstamp } = await readData(file);
//         console.log(file.name);
//         console.log('Old Header Timestamp:', headerTstamp);
//         console.log(new Date(headerTstamp * 1000));
//         const newHeaderTstamp = epochTime + headerTimeDelta + fileNumber * 120;
//         console.log('New Header Timestamp:', newHeaderTstamp);
//         console.log(new Date(newHeaderTstamp * 1000));

//         const updatedData = updateDeviceIdHtime(rawData, headerPosition, deviceId, newHeaderTstamp);

//         zip.file(file.name, updatedData);
//         modifiedFilesBuffer.push({ name: file.name, rawData: updatedData });

//         fileNumber++;
//       } catch (error) {
//         console.error(file.name, "Exception", error);
//       }
//     }

//     setModifiedFiles(modifiedFilesBuffer);

//     zip.generateAsync({ type: 'blob' }).then((content) => {
//       sendToBackend(content);
//       saveAs(content, 'updatedfiles.zip');
//     });
//   };

//   const numberOptions = {
//     'SIT-India': {
//       'DOZ': ['8235', '4497', '2217'],
//       'DP': ['11974', '11956', '6045'],
//     },
//     'SIT-US': {
//       'DOZ': ['8141', '8129', '8126'],
//       'DP': ['6081', '6128', '6139'],
//     },
//     'Prod-India': {
//       'DOZ': ['8075', '8055', '5605'],
//       'DP': ['750', '6376', '3941'],
//     },
//     'Prod-US': {
//       'DOZ': ['7842', '6821', '7790'],
//       'DP': ['6590', '7123', '7124'],
//     }
//   };

//   // Styling
//   const styles = {
//     container: {
//       padding: '20px',
//       fontFamily: 'Arial, sans-serif',
//       backgroundColor: '#f7f7f7',
//       borderRadius: '8px',
//       boxShadow: '0 0 10px rgba(0,0,0,0.1)',
//       maxWidth: '600px',
//       margin: 'auto'
//     },
//     header: {
//       fontSize: '24px',
//       marginBottom: '20px',
//       textAlign: 'center',
//     },
//     input: {
//       marginBottom: '10px',
//       width: '100%',
//       padding: '10px',
//       fontSize: '16px',
//       borderRadius: '4px',
//       border: '1px solid #ccc'
//     },
//     button: {
//       marginTop: '10px',
//       padding: '10px 20px',
//       fontSize: '16px',
//       backgroundColor: '#4CAF50',
//       color: 'white',
//       border: 'none',
//       borderRadius: '4px',
//       cursor: 'pointer',
//     },
//     select: {
//       marginBottom: '10px',
//       width: '100%',
//       padding: '10px',
//       fontSize: '16px',
//       borderRadius: '4px',
//       border: '1px solid #ccc'
//     },
//     error: {
//       color: 'red',
//       marginTop: '10px'
//     },
//     statusMessage: {
//       color: 'blue',
//       marginTop: '10px'
//     },
//     successMessage: {
//       color: 'green',
//       marginTop: '10px'
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.header}>File Processor</h1>
//       <input type="file" multiple onChange={handleFileChange} style={styles.input} />
//       <input type="text" value={timeString} onChange={handleTimeStringChange} placeholder="Enter time string" style={styles.input} />
//       <select value={selectedChoice} onChange={handleChoiceChange} style={styles.select}>
//         <option value="">Select Choice</option>
//         <option value="SIT-India">SIT-India</option>
//         <option value="SIT-US">SIT-US</option>
//         <option value="Prod-India">Prod-India</option>
//         <option value="Prod-US">Prod-US</option>
//       </select>
//       {selectedChoice && (
//         <div>
//           <select value={prefix} onChange={handlePrefixChange} style={styles.select}>
//             <option value="">Select Prefix</option>
//             <option value="DOZ">DOZ</option>
//             <option value="DP">DP</option>
//           </select>
//           {prefix && (
//             <select value={number} onChange={handleNumberChange} style={styles.select}>
//               <option value="">Select Number</option>
//               {numberOptions[selectedChoice][prefix].map((num) => (
//                 <option key={num} value={num}>{num}</option>
//               ))}
//             </select>
//           )}
//         </div>
//       )}
//       <button onClick={startReading} style={styles.button}>Start Processing</button>

//       {isUploading && <p style={styles.statusMessage}>Uploading...</p>}
//       {uploadSuccess && <p style={styles.successMessage}>Upload Successful!</p>}
//       {failedFiles.length > 0 && (
//         <div>
//           <p style={styles.error}>Failed to upload {failedFiles.length} files.</p>
//           <button onClick={retryFailedUploads} style={styles.button}>Retry Failed Uploads</button>
//         </div>
//       )}
//     </div>
//   );
// }

// export default FileProcessor;



import React, { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useEffect } from 'react';

function FileProcessor() {
  const [files, setFiles] = useState([]);
  const [deviceId, setDeviceId] = useState('');
  const [headerTimeDelta, setHeaderTimeDelta] = useState(0);
  const[fileNum,setFileNum]=useState(0)
  const [timeString, setTimeString] = useState('');
  const [selectedChoice, setSelectedChoice] = useState('');
  const [prefix, setPrefix] = useState('');
  const [number, setNumber] = useState('');
  const [modifiedFiles, setModifiedFiles] = useState([]);
  const [failedFiles, setFailedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false); // State to track if uploading
  const [uploadSuccess, setUploadSuccess] = useState(false); 
  const [globalbatches,setbatches]=useState([])// State to track success message
  useEffect(() => {
    if (failedFiles.length > 0) {
      handleRetryPrompt();
    }
  }, [failedFiles]);
 
  const BATCH_SIZE = 85; // Define the batch size

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const handleTimeStringChange = (event) => {
    setTimeString(event.target.value);
  };

  const handleChoiceChange = (event) => {
    setSelectedChoice(event.target.value);
    setPrefix('');
    setNumber('');
  };

  const handlePrefixChange = (event) => {
    setPrefix(event.target.value);
    setNumber('');
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const readData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const headerPosition = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i] === 252 || data[i] === 253) {
            headerPosition.push(i);
          }
        }

        const header = data.slice(headerPosition[0] + 1, headerPosition[1]);
        const headerStr = String.fromCharCode(...header);
        const headerTstamp = parseInt(headerStr.substring(0, 10), 10);
        resolve({ rawData: data, headerPosition, headerTstamp });
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const updateDeviceIdHtime = (rawData, headerPosition, deviceId, headerTstamp) => {
    const newDevice = Array.from(deviceId.toString()).map((char) => char.charCodeAt(0));
    const newHeader = Array.from(headerTstamp.toString()).map((char) => char.charCodeAt(0));

    for (let i = 0; i < newDevice.length; i++) {
      rawData[headerPosition[0] + 11 + i] = newDevice[i];
    }

    for (let i = 0; i < newHeader.length; i++) {
      rawData[headerPosition[0] + 1 + i] = newHeader[i];
    }

    return rawData;
  };

  const sendBatchToBackend = async (batch, batchIndex, size,isRetry = false) => {
    setIsUploading(true); // Set uploading state to true
    setUploadSuccess(false); // Reset success state

    const zip = new JSZip();
    const modifiedFilesBuffer = [];

    // Add files to ZIP for each batch
    console.log(batch)
    console.log(size)
    let num=size
    let filesize=batch.length
    
    for (const file of batch) {
      const { rawData, headerPosition, headerTstamp } = await readData(file);
      const newHeaderTstamp = Math.floor(new Date(timeString).getTime() / 1000) + num*120;
      if(isRetry===false){
        //console.log("here")
      const updatedData = updateDeviceIdHtime(rawData, headerPosition, deviceId, newHeaderTstamp);
      zip.file(file.name, updatedData);
     
      modifiedFilesBuffer.push({ name: file.name, rawData: updatedData });
      num+=1
      }
      else{
        zip.file(file.name,rawData);
        
      

      }
     
     
     

    
    }
    setFileNum(prevNum => prevNum + batch.length);
  
 
    setModifiedFiles(modifiedFilesBuffer)
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'updatedfiles.zip');
    console.log(`Sending batch ${batchIndex + 1}, size: ${(zipBlob.size / (1024 * 1024)).toFixed(2)} MB`);

    try {
      const formData = new FormData();
      formData.append('file', zipBlob, `updatedfiles_batch_${batchIndex + 1}.zip`);
      formData.append('prefix', prefix);
      formData.append('number', number);
      formData.append('choice',selectedChoice)
      

      const response = await fetch('https://analytics.dozeeapp.com/file', {
        method: 'POST',
        body: formData,
      });
      // const response=await fetch('http://127.0.0.1:8000/file',{
      //   method:'POST',
      //   body:formData,
      // });

      if (response.status === 200) {
        console.log(`Batch ${batchIndex + 1} uploaded successfully!`);
        setUploadSuccess(true); // Set success state to true
        if (isRetry) {
          setFailedFiles([]);
        }
      } else {
        console.error('Error uploading batch:', response.statusText);
        //console.error(response)
        const failedResponse = await response.json();
        console.log(failedResponse)
        if(failedResponse.failed_files.length!==filesize)
        setFailedFiles(failedResponse.failed_files || []);
        
        
      }
    } catch (error) {
      console.error('Exception during batch upload:', error);
      handleRetryPrompt();
    } finally {
      setIsUploading(false); // Set uploading state to false after the process
    }
  };

  const handleRetryPrompt = (batchIndex) => {
    
      retryFailedUploads(batchIndex);
    
  };

  const retryFailedUploads = (batchIndex) => {
    if (failedFiles.length > 0) {
      
     const batch=[]
     console.log("modified files",modifiedFiles)
      for (const file of failedFiles) {
        

        for (const file2 of globalbatches){
          if (file===file2.name){
            batch.push(file2)
          }
        }
      }
      sendBatchToBackend(batch,batchIndex,0, true);

      // zip.generateAsync({ type: 'blob' }).then((content) => {
      //   

      // });
    }
  };

  const startReading = async () => {
    console.log("Total files =", files.length);
    console.log(`Changing header time by ${headerTimeDelta / 60} mins Or ${headerTimeDelta / 3600} hrs`);

    const epochTime = Math.floor(new Date(timeString).getTime() / 1000);
    console.log("Epoch Time:", epochTime);

    const deviceID = "79f9494a-3bba-4271-98b0-035ec0799abf"; // Assuming you are modifying this ID for now
    if (!deviceID) {
      console.error("Device ID could not be retrieved.");
      return;
    }
    setDeviceId(deviceID);
    console.log(deviceID);

    // Process files in batches of 85
    const batches = [];
    
    for (let i = 0; i < files.length; i += BATCH_SIZE) {
      const batch = files.slice(i, i + BATCH_SIZE);
      batches.push(batch);
    }
    let num=0
    for (let batchIndex = 0; batchIndex < batches.length; batchIndex++) {
      const batch = batches[batchIndex];
      setbatches(batch)
     
      console.log(batch,"batches-content")
      await sendBatchToBackend(batch, batchIndex,num);
      num+=batch.length // Process each batch
    }
  };

  const numberOptions = {
    'SIT-India': {
      'TST': ['3501', '3504', '3505','3502','3503','3506','3507','3508','3509','3510','3511','3512','3513','3514','3515','3516','3517','3518','3519','3520','3521','3522','3523','3526','3527','3528','3529'],
      // 'DP': ['11974', '11956', '6045'],
    },
    'SIT-US': {
      'TST': ['3517', '3518','3501','3502','3503','3504','3505','3506','3507','3508','3509','3510','3511','3512','3513','3514','3515','3516','3519','3520','3521','3522','3523','3526','3527','3528'],
      // 'DP': ['6081', '6128', '6139'],
    },
    'Prod-India': {
      'TST': ['3510', '3511', '3513','3521','3517','3516','3512','3508','3528','3501','3502','3503','3504','3505','3506','3507','3508','3509','3512','3514','3515','3516','3517','3518','3519','3520','3522','3523','3526','3527','3528','3529'],
      // 'DP': ['750', '6376', '3941'],
    },
    'Prod-US': {
      'TST': ['3517', '3518','3501','3502','3503','3504','3505','3506','3507','3508','3509','3510','3511','3512','3513','3514','3515','3516','3519','3520','3521','3522','3523','3526','3527','3528'],
      // 'DP': ['6590', '7123', '7124'],
    },
    
  };

  // Styling
  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f7f7f7',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      maxWidth: '600px',
      margin: 'auto'
    },
    header: {
      fontSize: '24px',
      marginBottom: '20px',
      textAlign: 'center',
    },
    input: {
      marginBottom: '10px',
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc'
    },
    button: {
      marginTop: '10px',
      padding: '10px 20px',
      fontSize: '16px',
      backgroundColor: '#4CAF50',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    select: {
      marginBottom: '10px',
      padding: '10px',
      width: '100%',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc'
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>File Upload Processor</h1>

      <label htmlFor="device-choice">Select Server:</label>
      <select value={selectedChoice} onChange={handleChoiceChange} style={styles.select}>
        <option value="">Select Server</option>
        <option value="SIT-India">SIT-India</option>
        <option value="SIT-US">SIT-US</option>
        <option value="Prod-India">Prod-India</option>
        <option value="Prod-US">Prod-US</option>
      </select>

      <label htmlFor="prefix">Prefix:</label>
      <select value={prefix} onChange={handlePrefixChange} style={styles.select}>
        <option value="">Select Prefix</option>
        <option value="TST">TST</option>
        
        
      </select>

      <label htmlFor="number">Device ID:</label>
      <select value={number} onChange={handleNumberChange} style={styles.select}>
        <option value="">Select Device ID</option>
        {numberOptions[selectedChoice] && numberOptions[selectedChoice][prefix] && numberOptions[selectedChoice][prefix].map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>

      <label htmlFor="time-string">Time String:</label>
      <input type="text" value={timeString} onChange={handleTimeStringChange} style={styles.input} />

      <label htmlFor="file-upload">Upload Files:</label>
      <input type="file" multiple onChange={handleFileChange} style={styles.input} />

      <button onClick={startReading} style={styles.button} disabled={isUploading}>Start Processing</button>

      {isUploading && <p>Uploading files, please wait...</p>}
      {uploadSuccess && <p>Upload successful!</p>}
    </div>
  );
}

export default FileProcessor;
